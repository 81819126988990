<script>
import store from "@/store";
import whitelabelTemplatesAppAuthModule
  from "@/views/components/whitelabel-templates/App/config/PageEdit/AuthManagement/store/authStoreModule";
import {computed} from "@vue/composition-api";

export default {
  name: "AppRegister",
  props: ["config"],
  setup() {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates-auth";
    // Register module
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(
          MODULE_APP_STORE_NAME,
          whitelabelTemplatesAppAuthModule
      );

    const showRegisterDialog = computed(() => {
      return store.state["app-whitelabel-templates-auth"]
          .showRegisterAuthModal;
    });

    const handleHideDialog = () => {
      store.commit("app-whitelabel-templates-auth/HIDE_ALL");
    };

    return {
      showRegisterDialog,
      handleHideDialog,
    };
  },
  methods: {},
};
</script>

<template>
  <div class="kb-modal">
    <div
        class="modal fade"
        :class="{ show: showRegisterDialog, 'd-block': showRegisterDialog }"
        tabindex="-1"
        aria-hidden="true"
    >
      <div class="modal-dialog bg-dark kb-auth-modal-dialog" style="border-radius: 0.25rem">
        <div class="modal-content kb-auth-modal" style="border: none; background: var(--auth-modal-background-color)">
          <button
              @click="handleHideDialog()"
              type="button"
              class="close"
              style="right: 10px;text-shadow: none;"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <div class="kb-auth-modal-left">
            <img
                alt="register_modal_image"
                class="kb-auth-modal-left-desktop"
                :src="config.auth_modal_left_desktop_image"
            />
            <img
                alt="register_modal_image"
                class="kb-auth-modal-left-mobile"
                :src="config.auth_modal_left_mobile_image"
            />
          </div>

          <b-form class="kb-auth-modal-right" style="padding: 30px">
            <div class="kb-auth-modal-right-header form-group">
              <div class="text-center text-md pb-2 text-capitalize">
                whitelabel
              </div>
              Register
            </div>

            <b-form-group label-for="username">
              <b-form-input
                  style="background-color: var(--auth-modal-inputs-background-color);
                         pointer-events: none;
                         border:none;"
                  id="username"
                  type="text"
                  placeholder="Username"
                  :aria-describedby="`username-feedback`"
              ></b-form-input>
            </b-form-group>

            <b-form-group label-for="password">
              <b-input-group>
                <b-form-input
                    style="background-color: var(--auth-modal-inputs-background-color);
                         pointer-events: none;
                         border:none;"
                    id="login_password"
                    :type="'text'"
                    placeholder="Password"
                    :aria-describedby="`password-feedback`"
                ></b-form-input>
                <b-input-group-append>
                  <b-button variant="outline-secondary">
                    <b-icon :icon="'eye-slash-fill'"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <div class="form-group kb-auth-modal-right-submit">
              <button style="pointer-events: none; background-color: var(--auth-modal-submit-button-color) !important;" class="btn btn-success w-100">
                <span style="color: white">Register</span>
              </button>
            </div>

            <div class="form-group kb-auth-modal-right-footer">
              Do you already have an account?
              <a>Login</a>
            </div>
          </b-form>
        </div>
      </div>
    </div>
    <div
        class="modal-backdrop fade"
        :class="{ show: showRegisterDialog }"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/auth.scss";
</style>

<script>
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import store from "@/store";
import { createCssVar } from "@/views/components/whitelabel-templates/common/utils/createCssVar";
import appStoreModule from "@/views/components/whitelabel-templates/App/store/appStoreModule";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import AppHeader from "@/views/components/whitelabel-templates/App/components/common/header/AppHeader.vue";
import AppNav from "@/views/components/whitelabel-templates/App/components/common/navMenu/AppNav.vue";
import AppBanners from "@/views/components/whitelabel-templates/App/components/common/banners/AppBanners.vue";
import MainLayout from "@/views/components/whitelabel-templates/App/components/layouts/MainLayout.vue";
import AppFooter from "@/views/components/whitelabel-templates/App/components/common/footers/AppFooter.vue";
import AppHomePage from "@/views/components/whitelabel-templates/App/views/homePages/AppHomePage.vue";
import UserProfile from "@/views/components/whitelabel-templates/common/UserProfile/UserProfile.vue";
import AppLogin from "@/views/components/whitelabel-templates/App/components/common/AppLogin.vue";
import AppRegister from "@/views/components/whitelabel-templates/App/components/common/AppRegister.vue";

const MODULE_APP_STORE_NAME = "app-store";
const MODULE_WHITELABEL_STORE_NAME = "app-whitelabel-templates";

export default {
  name: "index",
  components: {
    AppRegister,
    AppLogin,
    UserProfile,
    AppHomePage,
    AppFooter,
    MainLayout,
    AppBanners,
    AppNav,
    AppHeader,
  },
  setup() {
    // Register modules
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, appStoreModule);
    if (!store.hasModule(MODULE_WHITELABEL_STORE_NAME))
      store.registerModule(MODULE_WHITELABEL_STORE_NAME, whitelabelTemplatesModule);

    const el = ref(null);

    const editTemplate = computed(
      () => store.state[MODULE_WHITELABEL_STORE_NAME].editTemplate
    );
    const preview = computed(() => store.state[MODULE_APP_STORE_NAME].preview);

    const loginConfig = computed(() => editTemplate.value.templateData.login);

    const registerConfig = computed(() => editTemplate.value.templateData.register);

    // Computed properties for configs
    const headerConfig = computed(() => editTemplate.value.templateData.header);
    const menuConfig = computed(() => editTemplate.value.templateData.menu);
    const bannersConfig = computed(
      () => editTemplate.value.templateData.banners
    );
    const homepageConfig = computed(
      () => editTemplate.value.templateData.homepage
    );
    const footerConfig = computed(() => editTemplate.value.templateData.footer);

    // Computed properties for colors
    const userProfileColors = computed(
      () => editTemplate.value.templateData.user_profile.colors
    );

    const mainColors = computed(
        () => editTemplate.value.templateData.main.colors
    );


    const setCssVar = () => {
      if (!el.value) return;
      createCssVar(el.value, {
        "--setting-bg-primary": userProfileColors.value.bgPrimary,
        "--setting-bg-secondary": userProfileColors.value.bgSecondary,
        "--setting-border": userProfileColors.value.colorBorder,
        "--setting-color-primary": userProfileColors.value.colorPrimary,
        "--setting-color-primary-text": userProfileColors.value.colorPrimaryText,
        "--setting-color-secondary": userProfileColors.value.colorSecondary,
        
        "--auth-modal-background-color": mainColors.value.auth_modal_background_color,
        "--auth-modal-color": mainColors.value.auth_modal_color,
        "--auth-modal-inputs-background-color": mainColors.value.auth_modal_inputs_background_color,
        "--auth-modal-inputs-color": mainColors.value.auth_modal_inputs_color,
        "--auth-modal-submit-button-color" : mainColors.value.auth_modal_submit_button_color,
      });
    };

    watch(userProfileColors, setCssVar, {
      deep: true,
      immediate: true,
    });

    onMounted(setCssVar);

    return {
      el,
      preview,
      editTemplate,
      headerConfig,
      menuConfig,
      bannersConfig,
      homepageConfig,
      footerConfig,
      userProfileColors,
      loginConfig,
      registerConfig
    };
  },
};
</script>

<template>
  <main ref="el" style="z-index: 5; position: absolute; width: 100%">
    <AppLogin :config="loginConfig" />
    <AppRegister :config="registerConfig"></AppRegister>
    <app-header
      :header="headerConfig"
      :type="null"
      :currencies="[]"
      :users="null"
      v-if="headerConfig"
    />
    <app-nav v-if="menuConfig" :menu="menuConfig"></app-nav>
    <app-banners type="popup" :current-page="'/'" :banners="bannersConfig" />
    <main-layout>
      <user-profile v-show="preview === 'profile'" />
      <AppHomePage v-show="preview === 'general'" :home-page="homepageConfig" />
    </main-layout>
    <app-footer :footer="footerConfig" v-if="footerConfig" />
  </main>
</template>

<style scoped lang="scss">

</style>
